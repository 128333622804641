import 'swiper/css';
import 'swiper/css/pagination';
import './css/main.css';
import './js/modernizr.js';


var Isotope = require('isotope-layout');
import 'isotope-packery';

import barba from '@barba/core';

import { gsap } from "gsap";

import 'lazysizes';

import Swiper, { Navigation, Pagination } from 'swiper';

import imagesLoaded from 'imagesloaded';
// import Swiper and modules styles

// import 'swiper/css/navigation';
// import 'swiper/css/pagination';

var SmoothScroll = require('smooth-scroll');

var scroll = new SmoothScroll('a[href*="#"]', {
    speed: 600,
    speedAsDuration: true
});

barba.hooks.beforeLeave((data) => {
  var scrolltop = new SmoothScroll();
  scrolltop.animateScroll(0, {
    speed: 500,
    speedAsDuration: true
  });
  
});

window.barba = barba;

barba.init({
  debug: true,
  timeout: 7000,
  transitions: [
    {
    name: 'opacity-transition',
    leave(data) {
      return gsap.to(data.current.container, {
        opacity: 0
      });
    },
    enter(data) {
      return gsap.from(data.next.container, {
        opacity: 0
      });
      
    },
    before(data) {
      // isoOpen();
      
    },
    once(data) {
        console.log("BEFOREONCE");
        openingScreen();
      isoOpen(data);
      plyrCheck();
        isoNewsOpen(data);
        swiperGallery(data);
        logoHide();
        titleChange(data);
        logoChange(data);
    }
  }],
});

// barba.hooks.once(() => {
//   console.log("BEFOREONCE");
//   openingScreen();
// });

function titleChange(data) {
  let title = document.getElementById('nameBox');
  if (data.next.namespace == 'story') {
    title.classList.add('text-black');
    title.classList.remove('text-shadow-xl');
    console.log('Changing title');
  } else {
    title.classList.remove('text-black');
    title.classList.add('text-shadow-xl');
  };
};

function logoChange(data) {
  let logo = document.getElementById('phyLogo');
  if (data.next.logoState == 'plantPage') {
    logo.classList.add('text-black');
    logo.classList.remove('text-white');
    console.log('Changing title');
  } else {
    logo.classList.remove('text-black');
    logo.classList.add('text-white');
   
  };
};


//   ---------------------------------- Menu ----------------------------------

function toggleMenu(preset) {

  var nav = document.getElementById("main-menu");
  var button = document.getElementById("menu-button");
  var status = nav.getAttribute("data-status");

  if (preset === "c") {
    nav.classList.add("translate-menu");
    button.innerHTML = '<div class="space-y-2"><div class="w-8 h-1 bg-black"></div><div class="w-8 h-1 bg-black"></div><div class="w-8 h-1 bg-black"></div></div>';
    nav.setAttribute("data-status", "c");
    button.classList.remove("text-black");
  } else if (status === "c") {
    nav.classList.remove("translate-menu");
    // button.innerHTML = "";
    nav.setAttribute("data-status", "o");
    button.classList.add("text-black");
  } else if (status === "o") {
    nav.classList.add("translate-menu");
    button.innerHTML = '<div class="space-y-2"><div class="w-8 h-1 bg-black"></div><div class="w-8 h-1 bg-black"></div><div class="w-8 h-1 bg-black"></div></div>';
    nav.setAttribute("data-status", "c");
    button.classList.remove("text-black");
  };

};


barba.hooks.leave((data) => {
  toggleMenu("c");
});

window.toggleMenu = toggleMenu;


//   ---------------------------------- Cat Menu ----------------------------------

function toggleCatMenu(preset) {

  var nav = document.getElementById("cat-menu");
  var button = document.getElementById("cat-button");
  var status = nav.getAttribute("data-status");

  if (preset === "c") {
    nav.classList.add("translate-menu");
    button.innerHTML = "Sort by";
    nav.setAttribute("data-status", "c");
    button.classList.remove("text-black");
  } else if (status === "c") {
    nav.classList.remove("translate-menu");
    // button.innerHTML = "Close";
    nav.setAttribute("data-status", "o");
    button.classList.add("text-black");
  } else if (status === "o") {
    nav.classList.add("translate-menu");
    button.innerHTML = "Sort by";
    nav.setAttribute("data-status", "c");
    button.classList.remove("text-black");
  };

};


barba.hooks.leave((data) => {
  toggleCatMenu("c");
});

window.toggleCatMenu = toggleCatMenu;



//   ---------------------------------- Lazy Load ----------------------------------

document.addEventListener('lazyloaded', function (e) {
  if (e.target.parentElement.classList.contains("imagebackground")) {
      setTimeout(function () {
          e.target.parentElement.style.backgroundColor = "transparent"
      }, 1000);
  } else {
      setTimeout(function () {
          e.target.style.backgroundColor = "transparent"
      }, 1000);
  };
});




// ------------------------------  Isotope ------------------------------


function isoOpen(data) {
  console.log("isoOpen()");
  var isogrid = document.getElementsByClassName("iso-grid");
  if ( isogrid.length > 0 ) {
  var iso = new Isotope( '.iso-grid', {
      itemSelector: '.grid-item',
      stamp: '.stamp',
      hiddenStyle: {
        opacity: 0
      },
      layoutMode: 'packery',
      percentPosition: true,
      // masonry: {
      //     columnWidth: '.grid-sizer',
      // },
      visibleStyle: {
        opacity: 1
      },
    });

    function showItems () {
        var grid = document.getElementsByClassName("iso-grid");
        var i;
        for (i = 0; i < grid.length; i++) {
            grid[i].style.opacity = 1;
        };
    };

    showItems();

    document.fonts.ready.then(function () {
      console.log("Fonts Loaded");
      iso.layout();
    });



    imageHeightFix();

    barba.hooks.beforeLeave((data) => {
      console.log("ISO DESTROY");
      iso.destroy();
      
    });

                     var isogrid2 = document.getElementsByClassName("iso-grid2");
                    if ( isogrid2.length > 0 ) {
                      var iso2 = new Isotope( '.iso-grid2', {
                          itemSelector: '.grid-item',
                          stamp: '.stamp',
                          hiddenStyle: {
                            opacity: 0
                          },
                          layoutMode: 'packery',
                          percentPosition: true,
                          // masonry: {
                          //     columnWidth: '.grid-sizer',
                          // },
                          visibleStyle: {
                            opacity: 1
                          },
                        });
                    
                        function showItems () {
                            var grid = document.getElementsByClassName("iso-grid2");
                            var i;
                            for (i = 0; i < grid.length; i++) {
                                grid[i].style.opacity = 1;
                            };
                        };
                    
                        showItems();
                    
                    
                    
                        imageHeightFix();
                    
                        barba.hooks.beforeLeave((data) => {
                          console.log("ISO DESTROY");
                          iso2.destroy();
                          
                        });
                    } else false;


                    var isogrid3 = document.getElementsByClassName("iso-grid3");
                    if ( isogrid3.length > 0 ) {
                      var iso3 = new Isotope( '.iso-grid3', {
                          itemSelector: '.grid-item',
                          stamp: '.stamp',
                          hiddenStyle: {
                            opacity: 0
                          },
                          layoutMode: 'packery',
                          percentPosition: true,
                          // masonry: {
                          //     columnWidth: '.grid-sizer',
                          // },
                          visibleStyle: {
                            opacity: 1
                          },
                        });
                    
                        function showItems () {
                            var grid = document.getElementsByClassName("iso-grid3");
                            var i;
                            for (i = 0; i < grid.length; i++) {
                                grid[i].style.opacity = 1;
                            };
                        };
                    
                        showItems();
                    
                    
                    
                        imageHeightFix();
                    
                        barba.hooks.beforeLeave((data) => {
                          console.log("ISO 3 DESTROY");
                          iso3.destroy();
                          
                        });
                    } else false;




    barba.hooks.after((data) => {
    
      if (data.current.namespace === "projects") {
        projectCheckHash();
      } else false;
    });
    
    barba.hooks.once((data) => {
      console.log("Once namespace" + data.next.namespace);
      if (data.next.namespace === "projects" || data.next.namespace === "calendar") {
        projectCheckHash();
      } else false;
    });


    
    // ------------------------------  Isotope filtering ------------------------------

    function isoFilter(slug) {
      toggleCatMenu("c");
      console.log(slug);
      var isotope2 = document.getElementsByClassName("iso-grid2");
      if (slug == "*" || slug == "") {
        resetFilterSelection();
        document.getElementById("all-dot").classList.remove("opacity-0");
        iso.arrange({
          filter: slug
        });
        if (isotope2.length > 0) {
          iso2.arrange({
            filter: slug
          });
        } else false;
        window.location.hash = "";
      } else {
        resetFilterSelection();
        document.getElementById(slug + "-dot").classList.remove("opacity-0");
        iso.arrange({
          filter: "." + slug
        });
        if (isotope2.length > 0) {
          iso2.arrange({
            filter: "." + slug
          });
        } else false;
        window.location.hash = slug;
      };
    };
    
    window.isoFilter = isoFilter;
    
    function resetFilterSelection() {
      var i;
      var dots = document.getElementsByClassName("filter-dots");
      for (i=0; i < dots.length; i++) {
        dots[i].classList.add("opacity-0");
      };

      var nav = document.getElementById("main-menu");
      var button = document.getElementById("menu-button");
      var status = nav.getAttribute("data-status");

      nav.classList.add("translate-menu");
      button.innerHTML = '<div class="space-y-2"><div class="w-8 h-1 bg-black"></div><div class="w-8 h-1 bg-black"></div><div class="w-8 h-1 bg-black"></div></div>';
      nav.setAttribute("data-status", "c");
      button.classList.remove("text-black");

    };
    
    barba.hooks.after((data) => {
      console.log("ISO LAYOUT");
      isoOpen(data);
    
      if (data.current.namespace === "projects" || data.current.namespace === "calendar") {
        projectCheckHash();
      } else false;
    });

      if (data.next.namespace === "projects" || data.next.namespace === "calendar") {
        projectCheckHash();
      } else false;
    
    
    function projectCheckHash() {
      var hash = window.location.hash.substring(1);
      console.log("Hash:" + hash);
      isoFilter(hash);
    };

    window.addEventListener('hashchange', projectCheckHash, false);


  };

};



barba.hooks.after((data) => {
  console.log("ISO LAYOUT");
  isoOpen(data);
});






// ------------------------------  News Iso ------------------------------


function isoNewsOpen() {

    var isogridnews = document.getElementsByClassName("iso-grid-news");
    if ( isogridnews.length > 0 ) {

      var isonews = new Isotope( '.iso-grid-news', {
        itemSelector: '.grid-item',
        stamp: '.stamp',
        hiddenStyle: {
          opacity: 0
        },
        layoutMode: 'packery',
        percentPosition: true,
        // masonry: {
        //     columnWidth: '.grid-sizer',
        // },
        visibleStyle: {
          opacity: 1
        },
      });

      function showNewsItems () {
        var grid = document.getElementsByClassName("iso-grid-news");
        var i;
        for (i = 0; i < grid.length; i++) {
            grid[i].style.opacity = 1;
        };
        };

        var loadelem = document.getElementById("iso-grid-news");

        imagesLoaded( loadelem, function() { isonews.layout();showNewsItems(); } );

        

        barba.hooks.beforeLeave((data) => {
          isonews.destroy();
        });

    } else false;

};


barba.hooks.after((data) => {
  isoNewsOpen(data);
});





// ------------------------------  Opening slides ------------------------------


function openingScreen() {
  var entry = document.getElementById("entryTitle");
  if (typeof(entry) != 'undefined' && entry != null && document.referrer.indexOf(window.location.hostname)==-1) {
    console.log("Opening");
    var entryTitle = document.getElementById("entryTitle");
    var entryText = document.getElementById("entryText");

    entryTitle.classList.remove("hidden");
    entryText.classList.remove("hidden");

    setTimeout(function(){ var entryText = document.getElementById("entryText"); entryText.classList.remove("opacity-0") }, 3000);
    setTimeout(function(){ 
    var entryText = document.getElementById("entryText"); 
    var entryTitle = document.getElementById("entryTitle");
    entryText.classList.add("opacity-0");
    entryTitle.classList.add("opacity-0");
   }
    , 8000);

  document.body.classList.remove("load-hide");
    setTimeout(function(){ 
      var entryText = document.getElementById("entryText"); 
      var entryTitle = document.getElementById("entryTitle");
      entryText.classList.add("hidden");
      entryTitle.classList.add("hidden");
     }
      , 8500);


  
  } else false;

};

function removeEntry() {
  console.log("Removing Entry");
   var entryText = document.getElementById("entryText"); 
    var entryTitle = document.getElementById("entryTitle");
    entryText.classList.add("hidden");
    entryTitle.classList.add("hidden");
};

window.removeEntry = removeEntry;

window.onload = function () {
  document.body.classList.remove("load-hide");
};






// ------------------------------  Image Height Fix ------------------------------


function imageHeightFix() {

  if (CSS.supports("aspect-ratio: 1 / 1")) {


      // Should only need to add this to the 'else' part of the if statement. This is a temporary fix for a safari bug.

      var images = document.getElementsByClassName("lazyload");
      var i;
      for (i = 0; i < images.length; i++) {
          var imgwidth = images[i].getAttribute("width");
          var imgheight = images[i].getAttribute("height");



          if (images[i].parentElement.classList.contains("imagebackground")) {
              var containerwidth = images[i].parentElement.clientWidth;
              var newheight = (imgheight / imgwidth) * containerwidth;
              images[i].parentElement.style.height = newheight + 'px';
          } else {
              var containerwidth = images[i].clientWidth;
              var newheight = (imgheight / imgwidth) * containerwidth;
              images[i].style.height = newheight + 'px';
          };

          // console.log(newheight);

      };

      document.addEventListener('lazyloaded', function (e) {
          if (e.target.parentElement.classList.contains("imagebackground")) {
              e.target.parentElement.style.height = 'auto';
          } else {
              e.target.style.height = 'auto';
          };
      });

  } else {
      var images = document.getElementsByClassName("lazyload");
      var i;
      for (i = 0; i < images.length; i++) {
          var imgwidth = images[i].getAttribute("width");
          var imgheight = images[i].getAttribute("height");



          if (images[i].parentElement.classList.contains("imagebackground")) {
              var containerwidth = images[i].parentElement.clientWidth;
              var newheight = (imgheight / imgwidth) * containerwidth;
              images[i].parentElement.style.height = newheight + 'px';
          } else {
              var containerwidth = images[i].clientWidth;
              var newheight = (imgheight / imgwidth) * containerwidth;
              images[i].style.height = newheight + 'px';
          };

          // console.log(newheight);

      };

      document.addEventListener('lazyloaded', function (e) {
          if (e.target.parentElement.classList.contains("imagebackground")) {
              e.target.parentElement.style.height = 'auto';
          } else {
              e.target.style.height = 'auto';
          };
      });
  };
};





// ------------------------------  Swiper ------------------------------



Swiper.use([Navigation, Pagination]); 

function swiperGallery(data) {
var swipercont = document.getElementById("swiper-gallery");
if (typeof(swipercont) != 'undefined' && swipercont != null ) {
    var swiperlength = document.getElementById("swiper-gallery").getAttribute("data-loopnum");

    var swiper = new Swiper('.swiper-container', {
    loop: true,
    init: true,
    preloadImages: false,
    slidesPerView: 'auto',
    watchSlidesVisibility: true,
    loopedSlides: swiperlength,
        centeredSlides: false,
        spaceBetween: 64,
    // If we need pagination
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    
    });

    swiper.on('afterInit', function () {
        // var currentslide = swiper.realIndex;
        // var caption = swiper.slides[currentslide].getAttribute("data-caption");

        // document.getElementById("gallery-caption").innerHTML = caption;

        // document.getElementById("gallery-fullscreen-caption").innerHTML = caption;

        // console.log("Swiper init");

        var currentslide = swiper.realIndex;
        var caption = swiper.slides[currentslide].getAttribute("data-caption");

        galleryNumber(swiperlength, currentslide);
      });
    
    swiper.init();


    swiper.on('slideChange', function () {
        // var currentslide = swiper.realIndex;
        // var caption = swiper.slides[currentslide].getAttribute("data-caption");
        
        // document.getElementById("gallery-fullscreen-caption").innerHTML = caption;
        // document.getElementById("gallery-caption").innerHTML = caption;

        var currentslide = swiper.realIndex;
        var caption = swiper.slides[currentslide].getAttribute("data-caption");

        galleryNumber(swiperlength, currentslide);

      });


    barba.hooks.beforeLeave((data) => {
      console.log("ISO DESTROY");
      iso.destroy();
      
    });


    function galleryNumber(swiperlength, currentslide) {
      var galleryno = document.getElementById("current-gallery-no");
      var galleryrange = document.getElementById("gallery-no-items");
      if (typeof (galleryno) != 'undefined' && galleryno != null) {
          galleryno.innerHTML = currentslide + 1;
          galleryrange.innerHTML = parseInt(swiperlength);
      } else false;
    };



    

      


} else false;


};

barba.hooks.after((data) => {
  swiperGallery();
});



function toggleSlideshow(preset) {

  var nav = document.getElementById("swiper-gallery-outer-container");
  var status = nav.getAttribute("data-status");

  if (preset === "c") {
    nav.classList.add("opacity-0");
    setTimeout(function() {nav.classList.add("hidden")}, 300);
    nav.setAttribute("data-status", "c");
  } else if (status === "c") {
    nav.classList.remove("hidden");
    setTimeout(function() {nav.classList.remove("opacity-0")}, 300);
    // button.innerHTML = "Close";
    nav.setAttribute("data-status", "o");
  } else if (status === "o") {
    nav.classList.add("opacity-0");
    setTimeout(function() {nav.classList.add("hidden")}, 300);
    nav.setAttribute("data-status", "c");
  };

};


barba.hooks.leave((data) => {
  toggleSlideshow("c");
});

window.toggleSlideshow = toggleSlideshow;





// ------------------------------  Hide logo ------------------------------

function logoHide() {
  var namespace = document.getElementById("site-container").getAttribute("data-barba-namespace");
  if (namespace == "home") {
    document.getElementById("site-logo").classList.add("opacity-0");
  } else {
    document.getElementById("site-logo").classList.remove("opacity-0");
  };
  console.log("logoHide");
};


// ------------------------------  VideoPly------------------------------

function plyrCheck() {
  let plyrs = document.getElementsByClassName('customPlayer');
  if (plyrs.length > 0) {
    import(/* webpackChunkName: "customPlayer" */ "./js/components/customPlayer.js").then(module => {
      const playerGo = module.default;
      playerGo();
    });
  };
};


barba.hooks.after((data) => {
  logoHide();
  plyrCheck();
  titleChange(data);
  logoChange(data);
});